<template>
  <div ref='current'>
    <el-autocomplete class="inline-input"
                     v-model="modelvalue"
                     :fetch-suggestions="querySearchAsync"
                     placeholder="请输入内容"
                     value-key='parkName'
                     :trigger-on-focus="false"
                     @select="handleSelect"
                     style='width:100%'></el-autocomplete>
    <!-- <el-select v-model="modelvalue" style='width:100%'  @change="handleSelect" placeholder="请选择">
            <el-option
            v-for="item in parkList"
            :key="item.parkId"
            :label="item.parkName"
            :value="item.parkId">
            </el-option>
        </el-select> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      className: 'my_auto_complete',
      modelvalue: '',
      restaurants: [],
      parkList: [],
      isScroll: true,
      page: 1,
      pageSize: 20
    }
  },
  props: {
    operationIdRequired: {
      default: false
    },
    slaveRelations: {
      default: '0,1,2'
    },
    parkType: {
      default: ''
    },
    areaIds: {
      default: ''
    },
    operationId: {
      default: ''
    }
  },
  watch: {
    modelvalue(newVal) {
      if (!newVal) {
        this.$emit('valueChange', '');
        this.$emit('dataChange', {});
      }
    }
  },
  methods: {
    setShowVal(val) {
      this.modelvalue = val;
    },
    setValue() {
      this.modelvalue = '';
      this.$emit('valueChange', '');
    },
    querySearchAsync(queryString, cb) {
      this.$emit('valueChange', '');
      queryString = queryString.replace(/\s/g, '');
      // 商户是不是必选
      if (this.operationIdRequired && !this.operationId) {
        cb([]);
        return;
      }
      let url = '/acb/2.0/systems/loginUser/getParkName'
      this.$axios.get(url, {
        data: {
          page: 1,
          pageSize: 15,
          parkName: queryString,
          slaveRelations: this.slaveRelations,
          parkType: this.parkType,
          areaIds: this.areaIds,
          dataSource: 1,
          operationId: this.operationId
        }
      }).then(res => {
        if (res.state == 0) {
          if (res.value.list.length) {
            this.$emit('dataChange', res.value.list[0]);
          } else {
            this.$emit('dataChange', { parkName: '', parkId: '' });
          }
          this.$emit('valueChange', '');
          cb(res.value.list);
        } else {
          cb([]);
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine')
          })
        }
      })
    },
    handleSelect(item) {
      this.$emit('valueChange', item.parkId);
    }
  },
  mounted() {
  }
}
</script>
