<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Payment_Order_ID')">
                <el-input
                  v-model="formInline.payOrderId"
                  :onkeyup="(formInline.payOrderId = formInline.payOrderId.replace(/[^\d]/g, ''))"
                  placeholder="请输入订单ID"
                  maxlength="19"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Road_or_Parking_Lot')">
                <my-component
                  ref="parkInput"
                  :areaIds="formInline.streetId ? formInline.streetId : formInline.areaId"
                  :operationId="formInline.operationId"
                  @valueChange="completeValue"
                  slaveRelations="0,1"
                ></my-component>
              </el-form-item>
              <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber">
                <el-autocomplete
                  valueKey="plateNumber"
                  class="inline-input"
                  v-model.trim="plateNumber"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入内容"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Berth_number')">
                <el-input
                  placeholder="请输入泊位号"
                  maxlength="30"
                  v-model="formInline.berthCode"
                ></el-input>
              </el-form-item>
              <!--          <el-form-item :label="$t('searchModule.Payment_method')">
            <el-select v-model.trim="formInline.payType"
                       filterable
                       placeholder=""
                       size="12">
              <el-option label="全部"
                         value=""></el-option>
              <el-option :label="pay.payTypeName"
                         :value="pay.payType"
                         :key="pay.payType"
                         v-for="(pay) in payTypeList"></el-option>
            </el-select>
          </el-form-item>-->
              <el-form-item :label="$t('searchModule.Merchant_affiliation')" prop="operationId">
                <el-select
                  @change="operationChange(formInline.operationId)"
                  v-model.trim="formInline.operationId"
                  filterable
                  size="15"
                >
                  <el-option label="全部" value></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in tenantList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Payment_channels')" prop="channelType">
                <el-select v-model.trim="formInline.channelType">
                  <el-option label="全部" value></el-option>
                  <el-option
                    :label="value.channelTypeDesc"
                    :value="value.channelType"
                    :key="value.channelType"
                    v-for="value in channelList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Payment_devices')" prop="devType">
                <el-select v-model.trim="formInline.devType">
                  <el-option label="全部" value></el-option>
                  <el-option
                    :label="value.desc"
                    :value="value.code"
                    :key="value.code"
                    v-for="value in plantList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Channel_serial_number')">
                <el-input
                  placeholder="渠道流水号"
                  maxlength="30"
                  v-model="formInline.tradeNo"
                ></el-input>
              </el-form-item>
              <!-- <el-form-item :label="$t('searchModule.Native_channel_serial_number')">
            <el-input placeholder="原生渠道流水号" maxlength="30" v-model="formInline.tradeNo"></el-input>
          </el-form-item> -->
              <!-- <el-form-item>
            <el-select v-model.trim="formInline.timeConditionType" filterable>
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>-->
              <!-- <el-form-item>
            <el-date-picker v-model="date1" type="datetime" placeholder="选择日期"@change="setStartTime"></el-date-picker>
            -
            <el-date-picker v-model="date2" type="datetime" placeholder="选择日期" @change="setEndTime"></el-date-picker>
          </el-form-item>-->
              <el-form-item :label="$t('searchModule.Payment_Time')">
                <!-- <el-date-picker v-model="date1"
                            type="datetime"
                            placeholder="选择日期"
                            @change="setStartTime"> </el-date-picker> 至
            <el-date-picker v-model="date2"
                            type="datetime"
                            placeholder="选择日期"
            @change="setEndTime"> </el-date-picker>-->
                <timeRangePick
                  @timeChange="timeChange"
                  ref="timeRangePicker"
                  :defaultRange="defaultRangeValue"
                  :defalutDate="defalutDate"
                />
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                v-if="$route.meta.authority.tabs.parkingPaymentOrder.button.query"
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
              <el-button type="info" icon="el-icon-delete" @click="resetForm()">{{ $t('button.reset') }}</el-button>
            </div>
          </div>
          <div
            class="col_box_boder"
            v-if="$route.meta.authority.tabs.parkingPaymentOrder.button.export"
          ></div>
          <div class="col_box h44">
            <div class="col_left"></div>
            <div class="col_right mbd4">
              <exportFile
                @exportFile="handleExportFile"
                :exportData="exportData"
                v-if="$route.meta.authority.tabs.parkingPaymentOrder.button.export"
              ></exportFile>
            </div>
          </div>
        </el-form>
      </div>
      <div class="total-wrapper">
        <div class="item-wrapper">
          <div class="text">支付记录</div>
          <span class="unit"
            ><span class="bold-wrap">{{ totalObj.orderNum || 0 }}</span
            >个</span
          >
        </div>
        <div class="item-wrapper">
          <div class="text">应收金额</div>
          <span class="unit">
            <span class="bold-wrap">
              {{
                totalObj.shouldPayMoney
                  ? totalObj.shouldPayMoney.toString().length > 8
                    ? (totalObj.shouldPayMoney / 100 / 10000).toFixed(2)
                    : (totalObj.shouldPayMoney / 100).toFixed(2)
                  : "0.00"
              }}
            </span>
            {{
              totalObj.shouldPayMoney && totalObj.shouldPayMoney.toString().length > 8
                ? "万元"
                : "元"
            }}
          </span>
        </div>
        <div class="item-wrapper">
          <div class="text">优惠金额</div>
          <span class="unit">
            <span class="bold-wrap">
              {{
                totalObj.couponMoney
                  ? totalObj.couponMoney.toString().length > 8
                    ? (totalObj.couponMoney / 100 / 10000).toFixed(2)
                    : (totalObj.couponMoney / 100).toFixed(2)
                  : "0.00"
              }}
            </span>
            {{ totalObj.couponMoney && totalObj.couponMoney.toString().length > 8 ? "万元" : "元" }}
          </span>
        </div>
        <div class="item-wrapper">
          <div class="text">实付金额</div>
          <span class="unit">
            <span class="bold-wrap">
              {{
                totalObj.money
                  ? totalObj.money.toString().length > 8
                    ? (totalObj.money / 100 / 10000).toFixed(2)
                    : (totalObj.money / 100).toFixed(2)
                  : "0.00"
              }}
            </span>
            {{ totalObj.money && totalObj.money.toString().length > 8 ? "万元" : "元" }}
          </span>
        </div>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <!--<el-table-column type="index" :label="$t('list.index')" width="70"></el-table-column>-->
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          >
            <template slot-scope="scope">
              <span
                v-if="item.prop == 'parkRecordId'"
                v-clipboard:copy="scope.row.parkRecordId"
                v-clipboard:success="onCopy"
                class="orderNumberStyle"
              >
                <p>{{ scope.row.parkRecordId }}</p>
              </span>
              <span
                v-else-if="item.prop == 'payOrderId'"
                v-clipboard:copy="scope.row.payOrderId"
                v-clipboard:success="onCopy"
                class="orderNumberStyle"
              >
                <p>{{ scope.row.payOrderId }}</p>
              </span>
              <span v-else>
                <p>{{ item.formatter ? item.formatter(scope.row) : scope.row[item.prop] }}</p>
              </span>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import timeRangePick from "@/components/timePicker";
import myComponent from "@/components/autocomplete/myautoComponent";
import autoComplete from "@/components/autocomplete";
import { dateFormat, setIndex } from "@/common/js/public.js";
import exportFile from "@/components/exportFile/exportFile.vue";

export default {
  name: "parkingPaymentOrder",
  props: ["data"],
  data() {
    return {
      defalutDate: [],
      options: [
        {
          value: 1,
          label: this.$t("list.entry_time"),
        },
        {
          value: 0,
          label: this.$t("list.Appearance_time"),
        },
      ],
      index: 0,
      total: 0,
      pageSize: 15,
      plateNumber: "",
      pageNum: 1,
      loading: false,
      detailFlag: false,
      areaList: [],
      parkList: [],
      berthList: [],
      payTypeList: [],
      operatorList: [],
      tenantList: [],
      channelList: [],
      plantList: [],
      tableCols: [
        {
          prop: "payOrderId",
          label: "支付订单ID",
          width: "180",
        },
        {
          prop: "parkRecordId",
          label: this.$t("list.Parking_Order_ID"),
          width: "180",
        },
        {
          prop: "tenantName",
          label: this.$t("list.Payment_merchant"),
        },
        {
          prop: "operationName",
          label: "平台商户",
          width: "",
        },
        {
          prop: "parkName",
          label: "道路/车场",
          width: "",
        },
        {
          prop: "berthNumber",
          label: this.$t("list.Berth_number"),
          width: "",
        },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "",
        },
        {
          prop: "entryTime",
          label: this.$t("list.entry_time"),
          width: "",
        },
        {
          prop: "exitTime",
          label: this.$t("list.billable_hours"),
          width: "",
        },
        {
          prop: "strParkTime",
          label: this.$t("list.Parking_duration"),
          width: "",
        },
        {
          prop: "shouldPay",
          label: this.$t("list.amount_receivable"),
          width: "",
          formatter: (row, column) => {
            if (row.shouldPay) {
              return Number(row.shouldPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "agioPay",
          label: this.$t("list.preferential_amount"),
          width: "",
          formatter: (row, column) => {
            if (row.agioPay) {
              return Number(row.agioPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "money",
          label: this.$t("list.Pay_amount"),
          width: "",
          formatter: (row, column) => {
            if (row.money) {
              return Number(row.money / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        // {
        // prop: 'payTypeDesc',
        // label: '支付方式',
        // width: ''
        // },
        {
          prop: "channelTypeDesc",
          label: this.$t("list.Payment_channels"),
          width: "",
        },
        {
          prop: "devTypeDesc",
          label: this.$t("list.Payment_devices"),
          width: "",
        },
        {
          prop: "operatorName",
          label: this.$t("list.Operator"),
          width: "",
        },
        {
          prop: "payParkName",
          label: "收费道路/车场",
          width: "",
        },
        {
          prop: "payTime",
          label: this.$t("list.payment_time"),
          width: "",
        },
        {
          prop: "tradeNo",
          label: "渠道流水号",
          width: "",
        },
        // {
        //   prop: "tradeNo",
        //   label: "原生渠道流水号",
        //   width: ""
        // }
      ],
      tableData: [],
      formInline: {
        payOrderId: "",
        parkId: "",
        carId: "",
        // payType: '',
        operationId: "",
        channelType: "",
        devType: "",
        startTime: "",
        endTime: "",
        name: `停车支付订单`,
        berthCode: "", // 泊位号
        // TOGAC-11294 dataSource=0 查询的是全部的，包含路内路外
        // TOGAC-12404 上边的注释是不对的，这个字段不传是全部的
        // dataSource: 0,
        // timeConditionType: 1
        tradeNo: "",
      },
      exportData: {},
      totalObj: {},
    };
  },
  watch: {
    data: {
      handler(newVal) {
        if (Object.keys(newVal).length > 0) {
          // this.formInline.tradeNo = newVal.outTradeNo;
          // this.defalutDate = [
          //   newVal.createTime, newVal.createTime
          // ]
          //  this.datetimerange = [];
          // this.formInline.startTime = '';
          // this.formInline.endTime =  '';
          console.log("支付订单", newVal);
          console.log("defalutDate", this.defalutDate);
        }
      },
      immediate: true,
    },
    plateNumber(newVal) {
      if (!newVal) {
        this.formInline.carId = "";
      }
    },
  },
  methods: {
    onCopy() {
      this.$message({
        message: "已复制",
        type: "success",
      });
    },
    dataTimeRest() {
      let nowDate = new Date();
      let year = nowDate.getFullYear();
      let month =
        nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1) : nowDate.getMonth() + 1;
      let day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();
      let dateStr = year + "-" + month + "-" + day;
      return dateStr;
    },
    timeChange(timeArr, shortcutKey) {
      this.formInline.startTime = timeArr[0];
      this.formInline.endTime = timeArr[1];
      if (shortcutKey) {
        this.searchData();
      }
    },
    operationChange(val) {
      this.formInline.operationId = val;
    },
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
          // console.log(this.tenantList);
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    // 获取渠道列表
    getChannelList() {
      this.$axios.get("/acb/2.0/payOrder/payChannelTypeDictList").then((res) => {
        if (res.state == 0) {
          this.channelList = res.value || [];
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },

    // 获取设备列表
    getPlantList() {
      this.$axios.get("/acb/2.0/payOrder/devTypeList").then((res) => {
        if (res.state == 0) {
          this.plantList = res.value || [];
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },

    setStartTime(value) {
      this.formInline.startTime = dateFormat(value);
    },
    setEndTime(value) {
      this.formInline.endTime = dateFormat(value);
    },
    resetForm() {
      this.plateNumber = "";
      this.formInline = {
        payOrderId: "",
        berthCode: "", // 泊位号
        areaId: "",
        streetId: "",
        operationId: "",
        parkId: "",
        parkType: "1",
        plateNumber: "",
        entryOrExit: 1,
        startTime: "",
        endTime: "",
        mobile: "",
        channelType: "",
        devType: "",
        // timeConditionType: 1,
        // payType: ''
      };
      this.$refs.timeRangePicker.resetTime();
      this.formInline.parkId = "";
      this.formInline.parkName = "";
      this.$refs.parkInput.setValue();
      this.pageNum = 1;
      this.searchData();
    },
    handleExportFile() {
      // 导出数据
      this.exportData = {
        url: "/acb/2.0/payOrder/payOrderList/export",
        methods: "post",
        data: this.formInline,
      };
    },
    handleSelect(item) {
      this.formInline.plateNumber = item.plateNumber;
      this.formInline.carId = item.carId;
      this.pageNum = 1;
      this.searchData();
    },
    querySearchAsync(queryString, cb) {
      this.formInline.carId = "";
      queryString = queryString.replace(/\s/g, "");
      if (queryString.length < 3) {
        cb([]);
        return;
      }
      this.$axios
        .get("/acb/2.0/parkRecord/plateNumber/list", {
          data: {
            part: queryString,
            size: 100,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        });
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    showLog() {
      if (this.formInline.startTime && this.formInline.endTime) {
        let time = new Date(this.formInline.endTime) - new Date(this.formInline.startTime);
        time = time / (24 * 60 * 60 * 1000);
        if (time >= 31) {
          this.$alert("查询时间不能大于31天");
          return false;
        } else {
          return true;
        }
      } else {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },
    // 获取支付方式
    getPay() {
      let opt = {
        method: "get",
        url: "/acb/2.0/payment/payTypeDictList",
        success: (res) => {
          if (res.state == 0) {
            this.payTypeList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },

    /**
     * 统计结果
     */
    getTotalList(data) {
      let opt = {
        method: "get",
        url: "/acb/2.0/payOrder/total",
        data,
        success: (res) => {
          this.loading = false;
          if (res.state == 0) {
            this.totalObj = res.value || {};
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
        fail: (res) => {
          this.totalObj = {};
        },
      };
      this.$request(opt);
    },

    searchData() {
      // let flag = this.$route.params.tradeNo || this.$route.query.orderId ? true : this.showLog();
      // if (!flag) return;
      let opt = {
        method: "get",
        url: "/acb/2.0/payOrder/payOrderList",
        data: {
          payOrderId: this.formInline.payOrderId,
          page: this.pageNum,
          pageSize: this.pageSize,
          payType: this.formInline.payType,
          startTime: this.formInline.startTime,
          endTime: this.formInline.endTime,
          parkId: this.formInline.parkId,
          carId: this.formInline.carId,
          operationId: this.formInline.operationId,
          channelType: this.formInline.channelType,
          devType: this.formInline.devType,
          berthCode: this.formInline.berthCode,
          operatorName: this.formInline.operatorName, // 操作人
          // TOGAC-11294 dataSource=0 查询的是全部的，包含路内路外
          // TOGAC-12404 上边的注释是不对的，这个字段不传是全部的
          // dataSource: 0,
          // timeConditionType: this.formInline.timeConditionType
          tradeNo: this.formInline.tradeNo,
        },
        success: (res) => {
          this.tableData = [];
          this.loading = false;
          if (res.state == 0) {
            this.tableData = setIndex(this.pageNum, res.value.list);
            this.total = res.value.total * 1 || 0;
            this.getTotalList(opt.data);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
        fail: (res) => {
          this.tableData = [];
          this.totalObj = {};
          this.loading = false;
        },
      };
      // if (flag) {
      this.loading = true;
      this.$request(opt);
      // }
    },
  },
  components: {
    myComponent,
    exportFile,
    timeRangePick,
  },
  activated() {
    if (this.$route.params.tradeNo) {
      //  this.formInline.tradeNo = this.$route.params.tradeNo
      //  this.$refs.timeRangePicker.resetTime2();
      //  this.formInline.startTime = ""
      //  this.formInline.endTime = ""
    } else {
      // this.defalutDate = [
      //   this.dataTimeRest() + " 00:00:00",
      //   this.dataTimeRest() + " 23:59:59"
      // ];
    }
    setTimeout(() => {
      //  this.searchData();
    }, 100);
  },
  created() {
    console.log("支付订单查询权限", this.$route.meta.authority);
  },
  mounted() {
    this.getTenantList();
    this.getChannelList();
    this.getPlantList();
    // this.getPay();
    // 导出数据
    this.exportData = {
      url: "/acb/2.0/payOrder/payOrderList/export",
      methods: "post",
      data: this.formInline,
    };
    console.log(this.data.outTradeNo, "this.data.outTradeNo");
    if (this.data.outTradeNo) {
      this.formInline.tradeNo = this.data.outTradeNo;
      this.formInline.startTime = "";
      this.formInline.endTime = "";
      this.$refs.timeRangePicker.resetTime2(["", ""]);
    }
    if (this.$route.query.orderId) {
      this.formInline.payOrderId = this.$route.query.orderId;
      this.formInline.startTime = "";
      this.formInline.endTime = "";
      this.$refs.timeRangePicker.resetTime2(["", ""]);
    }
    this.searchData();
  },
  computed: {
    defaultRangeValue() {
      return [
        {
          typeNumber: this.formInline.plateNumber,
          warntip: "请输入您查询的车牌号/泊位号",
        },
      ];
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  overflow: hidden;



  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}
.orderNumberStyle {
  color: #0f6eff;
}

.tableWrapper {
  padding: 0 20px 20px;
}

.total-wrapper {
  padding: 0 20px;
  margin-bottom: 22px;
  .item-wrapper {
    min-width: 88px;
    margin-right: 8px;
    padding: 6px 16px;
    display: inline-block;
    background: #F4F8FF;
    border-radius: 4px;
  }

  .text {
    line-height: 17px;
    color: #425466;
    font-size: 12px;
  }

  .bold-wrap {
    padding-right: 2px;
    position: relative;
    top: 1px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #0f6eff;
    line-height: 25px;
  }

  .unit {
    color: #6C7293;
    font-size: 12px;
  }
}
</style>
