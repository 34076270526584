<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.order_ID')">
                <el-input
                  v-model="formInline.preOrderId"
                  :onkeyup="(formInline.preOrderId = formInline.preOrderId.replace(/[^\d]/g, ''))"
                  placeholder="请输入订单ID"
                  maxlength="19"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Parking_record_ID')">
                <el-input
                  v-model="formInline.parkRecordId"
                  :onkeyup="
                    (formInline.parkRecordId = formInline.parkRecordId.replace(/[^\d]/g, ''))
                  "
                  placeholder="请输入停车记录ID"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Merchant_affiliation')">
                <el-select v-model.trim="formInline.operationId" filterable size="15">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in roleList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <!--          <el-form-item :label="$t('searchModule.park_name')">
            <auto-complete
              ref="select"
              @dataChange="(v) => (temParkData = v)"
              slaveRelations="0,1,2"
              @valueChange="completeValue"
              :operationId="formInline.operationId"
            ></auto-complete>
          </el-form-item>-->
              <el-form-item :label="$t('searchModule.Operator')">
                <el-input v-model="formInline.operatorName" placeholder="请输入操作人"></el-input>
              </el-form-item>
              <el-form-item label="">
                <el-select
                  v-model.trim="formInline.timeConditionType"
                  filterable
                  placeholder="请选择"
                >
                  <el-option :label="$t('searchModule.Payment_Time')" :value="0"></el-option>
                  <el-option :label="$t('searchModule.Deduction_time')" :value="1"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="">
                <timeRangePick
                  @timeChange="timeChange"
                  ref="timeRangePicker"
                  :defalutDate="defalutDate"
                />
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  searchData();
                  page = 1;
                "
                :loading="loading"
                >查询
              </el-button>
              <el-button icon="el-icon-delete" @click="resetForm()">{{ $t('button.reset') }}</el-button>
            </div>
          </div>
          <div class="col_box_boder" v-if="$route.meta.authority.button.export"></div>
          <div class="col_box h44" v-if="$route.meta.authority.button.export">
            <div class="col_left"></div>
            <div class="col_right mbd4">
              <exportFile style="display: inline-block" :exportData="exportData"></exportFile>
            </div>
          </div>
        </el-form>
      </div>
      <div class="total-wrapper">
        <div class="item-wrapper">
          <div class="text">支付记录</div>
          <span class="unit"
            ><span class="bold-wrap">{{ totalObj.orderNum || 0 }}</span
            >个</span
          >
        </div>
        <div class="item-wrapper">
          <div class="text">预付金额</div>
          <span class="unit">
            <span class="bold-wrap">
              {{
                totalObj.preMoney
                  ? totalObj.preMoney.toString().length > 8
                    ? (totalObj.preMoney / 100 / 10000).toFixed(2)
                    : (totalObj.preMoney / 100).toFixed(2)
                  : "0.00"
              }}
            </span>
            {{ totalObj.preMoney && totalObj.preMoney.toString().length > 8 ? "万元" : "元" }}
          </span>
        </div>
        <div class="item-wrapper">
          <div class="text">已抵扣金额</div>
          <span class="unit">
            <span class="bold-wrap">
              {{
                totalObj.deductMoney
                  ? totalObj.deductMoney.toString().length > 8
                    ? (totalObj.deductMoney / 100 / 10000).toFixed(2)
                    : (totalObj.deductMoney / 100).toFixed(2)
                  : "0.00"
              }}
            </span>
            {{ totalObj.deductMoney && totalObj.deductMoney.toString().length > 8 ? "万元" : "元" }}
          </span>
        </div>
        <div class="item-wrapper">
          <div class="text">退款金额</div>
          <span class="unit">
            <span class="bold-wrap">
              {{
                totalObj.refundMoney
                  ? totalObj.refundMoney.toString().length > 8
                    ? (totalObj.refundMoney / 100 / 10000).toFixed(2)
                    : (totalObj.refundMoney / 100).toFixed(2)
                  : "0.00"
              }}
            </span>
            {{ totalObj.refundMoney && totalObj.refundMoney.toString().length > 8 ? "万元" : "元" }}
          </span>
        </div>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
          min-height="400"
        >
          <!--          <el-table-column
            type="index"
            :label="$t('list.index')"
            :index="indexMethod"
            width="70"
          ></el-table-column>-->
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            align="center"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          >
          </el-table-column>
          <el-table-column :label="$t('searchModule.Parking_record_ID')" width="" align="center">
            <template slot-scope="scope">
              <span
                style="color: #20a0ff; cursor: pointer"
                v-clipboard:copy="scope.row.parkRecordId"
                v-clipboard:success="onCopy"
                >{{ scope.row.parkRecordId }}</span
              >
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper" v-show="total > 0">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// exportExcelNew
import { exportExcelNew, formatDuring } from "@/common/js/public";
import timeRangePick from "@/components/timePicker/timePicker";
// import autoComplete from "@/components/autocomplete";
import exportFile from "@/components/exportFile/exportFile.vue";

export default {
  name: "parkPayOrder",
  props: ["data"],
  data() {
    return {
      defalutDate: [],
      roleList: [],
      total: 0,
      pageSize: 15,
      page: 1,
      loading: false,
      tableCols: [
        {
          prop: "preOrderId",
          label: this.$t("list.order_ID"),
        },
        {
          prop: "tenantName",
          label: this.$t("list.Payment_merchant"),
          width: "100",
        },
        {
          prop: "operationName",
          label: this.$t("list.Merchant_affiliation"),
          width: "",
        },
        {
          prop: "channelTypeDesc",
          label: this.$t("list.Payment_channels"),
          width: "",
        },
        {
          prop: "devTypeDesc",
          label: this.$t("list.Payment_devices"),
          width: "",
        },
        {
          prop: "operatorName",
          label: this.$t("list.Operator"),
          width: "",
        },
        {
          prop: "payTime",
          label: this.$t("list.payment_time"),
          width: "",
        },
        {
          prop: "preMoney",
          label: this.$t("list.prepaid_amount"),
          width: "",
          formatter: (row) => {
            if (row.preMoney == 0) {
              return 0;
            } else {
              // 将分转成元
              var str = (row.preMoney / 100).toFixed(2) + "";
              // 取到整数部分
              var intSum = str.substring(0, str.indexOf(".")).replace(/\B(?=(?:\d{3})+$)/g, ",");
              // 取到小数部分
              var dot = str.substring(str.length, str.indexOf("."));
              return intSum + dot;
            }
          },
        },
        {
          prop: "deductMoney",
          label: this.$t("list.Deducted_amount"),
          width: "",
          formatter: (row) => {
            if (row.status == 0) {
              return "";
            } else {
              if (row.deductMoney == 0) {
                return 0;
              } else {
                // 将分转成元
                var str = (row.deductMoney / 100).toFixed(2) + "";
                // 取到整数部分
                var intSum = str.substring(0, str.indexOf(".")).replace(/\B(?=(?:\d{3})+$)/g, ",");
                // 取到小数部分
                var dot = str.substring(str.length, str.indexOf("."));
                return intSum + dot;
              }
            }
          },
        },
        {
          prop: "refundMoney",
          label: this.$t("list.refund_amount"),
          width: "",
          formatter: (row) => {
            if (row.status == 0) {
              return "";
            } else {
              if (row.refundMoney == 0) {
                return 0;
              } else {
                // 将分转成元
                var str = (row.refundMoney / 100).toFixed(2) + "";
                // 取到整数部分
                var intSum = str.substring(0, str.indexOf(".")).replace(/\B(?=(?:\d{3})+$)/g, ",");
                // 取到小数部分
                var dot = str.substring(str.length, str.indexOf("."));
                return intSum + dot;
              }
            }
          },
        },
        {
          prop: "deductTime",
          label: this.$t("list.Deduction_time"),
          width: "",
        },
        //        {
        //          prop: "intervalTime",
        //          label: "间隔时长",
        //          width: "100",
        //          formatter: (row) => {
        //            return formatDuring(row.intervalTime * 60, "minute");
        //          },
        //        },
      ],
      tableData: [],
      formInline: {
        name: `停车预付订单`,
        operationId: "",
        operatorName: "", // 操作人姓名
        startTime: "",
        endTime: "",
        parkRecordId: "", // 停车记录ID
        preOrderId: "", // 订单ID
        timeConditionType: 0, // 时间类型
      },
      exportData: {},
      totalObj: {},
    };
  },
  watch: {
    data: {
      handler(newVal) {
        console.log("newVal1-->", newVal);
        if (Object.keys(newVal).length > 0) {
          //  this.formInline.startTime = newVal.startDate ? newVal.startDate : this.formInline.startTime
          //  this.formInline.endTime = newVal.endDate ?  newVal.endDate : this.formInline.endTime
          //  this.$refs.timeRangePicker.resetTime2([newVal.startDate, newVal.endDate])

          console.log(" this.formInline.startTime-->", this.formInline.startTime);
        }
      },
      immediate: true,
    },
  },
  methods: {
    // 清空
    resetForm() {
      this.formInline = {
        name: `停车预付订单`,
        operationId: "",
        operatorName: "",
        startTime: "",
        endTime: "",
        parkRecordId: "", // 停车记录ID
        preOrderId: "", // 订单ID
        timeConditionType: 0, // 时间类型
      };
      this.defalutDate = [this.dataTimeRest() + " 00:00:00", this.dataTimeRest() + " 23:59:59"];
      this.$refs.timeRangePicker.resetTime();
      this.exportData = {
        url: "/acb/2.0/preOrder/prePayOrderList/export",
        methods: "post",
        data: this.formInline,
      };
      this.page = 1;
      this.searchData();
    },
    // 复制
    onCopy() {
      this.$message.success("停车记录ID已复制！");
    },
    dataTimeRest() {
      let nowDate = new Date();
      let year = nowDate.getFullYear();
      let month =
        nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1) : nowDate.getMonth() + 1;
      let day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();
      let dateStr = year + "-" + month + "-" + day;
      return dateStr;
    },
    timeChange(timeArr) {
      this.formInline.startTime = timeArr[0];
      this.formInline.endTime = timeArr[1];
    },
    /* 商户名称的下拉框 */
    getRoleList() {
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/operation/list",
        data: {
          page: 0,
          pageSize: 0,
          companyName: "",
          bearSide: "",
        },
        success: (res) => {
          this.loading = false;
          if (res.state == 0) {
            this.roleList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    indexMethod(index) {
      return index + 1;
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    showLog() {
      if (this.formInline.startTime && this.formInline.endTime) {
        let time = new Date(this.formInline.endTime) - new Date(this.formInline.startTime);
        time = time / (24 * 60 * 60 * 1000);
        if (time >= 31) {
          this.$alert("查询时间不能大于31天");
          return false;
        } else {
          return true;
        }
      } else {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },
    // 导出
    exportFileBtn() {
      let opt = {
        ...this.formInline,
      };
      exportExcelNew("/acb/2.0/preOrder/prePayOrderList/export", opt);
    },

    /**
     * 统计结果
     */
    getTotalList() {
      let opt = {
        method: "get",
        url: "/acb/2.0/preOrder/total",
        data: {
          page: this.page,
          pageSize: this.pageSize,
          ...this.formInline,
        },
        success: (res) => {
          this.loading = false;
          if (res.state == 0) {
            this.totalObj = res.value || {};
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
        fail: (res) => {
          this.totalObj = {};
          this.loading = false;
        },
      };
      this.$request(opt);
    },

    // 搜索
    searchData() {
      // this.tableData = [];
      if (!this.showLog()) {
        return;
      }
      this.$axios
        .get("/acb/2.0/preOrder/list", {
          data: {
            ...this.formInline,
            page: this.page,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.tableData = res.value.list;
            this.total = Number(res.value.total) * 1;
            this.getTotalList();
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        })
        .catch(() => {
          this.tableData = [];
          this.totalObj = {};
        });
    },
  },
  components: {
    // InfoList,
    // picDetail,
    // autoComplete,
    exportFile,
    timeRangePick,
  },
  activated() {},
  created() {
    this.defalutDate = [this.dataTimeRest() + " 00:00:00", this.dataTimeRest() + " 23:59:59"];

    if (this.$route.params.pdaManagerName) {
      this.defalutDate = [this.$route.params.startTime, this.$route.params.endTime];
      this.formInline.startTime = this.$route.params.startTime;
      this.formInline.endTime = this.$route.params.endTime;
      this.formInline.operatorName = this.$route.params.pdaManagerName;
      this.searchData();
    }
  },
  mounted() {
    // this.formInline = {
    //   parkId: '',
    //   operationId: '',
    //   pdaManagerName: '',
    //   intervalTime: '',
    //   recordStartTime: this.formInline.recordStartTime,
    //   recordEndTime: this.formInline.recordEndTime
    // }
    this.getRoleList();
    if (this.data.outTradeNo) {
      this.formInline.tradeNo = this.data.outTradeNo;
    }

    this.exportData = {
      url: "/acb/2.0/preOrder/prePayOrderList/export",
      methods: "post",
      data: this.formInline,
    };
    if (this.data.startDate) {
      console.log("c测试", this.data);
      // this.formInline.operatorName =  this.$route.query.pdaManagerName ? this.$route.query.pdaManagerName : this.formInline.operatorName;
      // this.formInline.startTime = this.$route.query.startTime ? this.$route.query.startTime : this.formInline.startTime
      // this.formInline.endTime = this.$route.query.endTime ?  this.$route.query.endTime : this.formInline.endTime
      // this.$refs.timeRangePicker.resetTime2([this.$route.query.startTime, this.$route.query.endTime])
      this.formInline.operationId = this.data.operationId
        ? this.data.operationId
        : this.formInline.operationId;
      this.formInline.startTime = this.data.actuPayTime
        ? this.data.actuPayTime
        : this.formInline.startTime;
      this.formInline.endTime = this.data.actuPayTime
        ? this.data.actuPayTime
        : this.formInline.endTime;
      this.$refs.timeRangePicker.resetTime2([this.data.actuPayTime, this.data.actuPayTime]);
    }
    this.searchData();
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
::v-deep .el-form-item.time_item {
  min-width: 0px
  .el-input__inner {
    min-width: 243px
  }
}
.conborder {
  border-top: 1px solid #C0CCDA;
  overflow: hidden;
  padding: 10px;
}

.content {
  overflow: hidden;
  background: none;


  .pagerWrapper {
    text-align: right;
    margin-top: 18px;
    font-size: 12px;

    .export {
      font-size: 12px;
      border: 1px solid #0099FA;
      border-radius: 4px;
      color: #0099FA;
      padding: 8px 13px;
      cursor: pointer;

      .iconfont {
        margin-right: 14px;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.tableWrapper {
  padding: 0 20px 20px;
}

.total-wrapper {
  padding: 0 20px;
  margin-bottom: 22px;
  .item-wrapper {
    min-width: 88px;
    margin-right: 8px;
    padding: 6px 16px;
    display: inline-block;
    background: #F4F8FF;
    border-radius: 4px;
  }

  .text {
    line-height: 17px;
    color: #425466;
    font-size: 12px;
  }

  .bold-wrap {
    padding-right: 2px;
    position: relative;
    top: 1px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #0f6eff;
    line-height: 25px;
  }

  .unit {
    color: #6C7293;
    font-size: 12px;
  }
}
</style>
<style scoped>
.searchWrapper .el-form-item {
  min-width: auto;
}
.parkTitle {
  border-bottom: 1px solid #e1e1e1;
}

.parkTitle span {
  text-align: center;
  display: inline-block;
  width: 100px;
  line-height: 30px;
  border: 1px solid #e1e1e1;
  border-bottom: 0;
}

.pic {
  margin-top: 20px;
}

.pic img {
  width: 200px;
  height: 200px;
}

.picTitle {
  line-height: 30px;
}

.detail1 {
  line-height: 25px;
}
</style>
