var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("el-date-picker", {
        attrs: {
          "value-format": _vm.valueFormat,
          type: _vm.type,
          "range-separator": "至",
          "default-time": ["00:00:00", "23:59:59"],
          clearable: _vm.clearable,
          "start-placeholder": "开始日期",
          "end-placeholder": "结束日期",
        },
        on: { change: _vm.changeTime },
        model: {
          value: _vm.timeSection,
          callback: function ($$v) {
            _vm.timeSection = $$v
          },
          expression: "timeSection",
        },
      }),
      _c("i", { staticClass: "el-icon-date el-icon-date-custom" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }