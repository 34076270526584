<template>
  <div>
    <el-date-picker
      :value-format="valueFormat"
      v-model="timeSection"
      :type="type"
      range-separator="至"
      :default-time="['00:00:00', '23:59:59']"
      @change="changeTime"
      :clearable="clearable"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
    >
    </el-date-picker>
    <i class="el-icon-date el-icon-date-custom"></i>
  </div>
</template>

<script>
import { getZeroOrLastDateTime } from "@/common/js/utils";
import { dateFormat } from "@/common/js/public.js";
export default {
  props: {
    valueFormat: {
      type: String,
      default: "yyyy-MM-dd HH:mm:ss",
    },
    type: {
      type: String,
      default: "datetimerange",
    },
    // 是否显示清除按钮
    clearable: {
      type: Boolean,
      default: true,
    },
    defalutDate: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  watch: {
    valueFormat: function (format) {
      this.resetTime();
    },
  },
  data() {
    const newDate = new Date();
    newDate.setTime(newDate.getTime());
    return {
      timeSection: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > newDate - 3600 * 1000 * 24 * 1; // 当天往后置灰（不包括当天）
          // return time.getTime() > newDate;   // 当天往后置灰
        },
      },
      timeArray: [
        getZeroOrLastDateTime(this.valueFormat, 0),
        getZeroOrLastDateTime(this.valueFormat, 0, false),
      ], // 当天0点 - 23：59
      dayArray: [
        getZeroOrLastDateTime(this.valueFormat, -1),
        getZeroOrLastDateTime(this.valueFormat, 0),
      ], // 昨天  - 今天
      monthArray: [
        getZeroOrLastDateTime(this.valueFormat, -365),
        getZeroOrLastDateTime(this.valueFormat, -30),
      ], // 12月前 - 当月
    };
  },
  methods: {
    // 重置成初始值
    resetTime() {
      if (this.defalutDate.length > 0) {
        // 如果有默认时间显示默认时间
        console.log("this.defalutDate-<", this.defalutDate);
        this.timeSection = [...this.defalutDate];
      } else {
        if (this.valueFormat == "yyyy-MM-dd") {
          this.timeSection = this.dayArray;
        } else if (this.valueFormat == "yyyy-MM") {
          this.timeSection = this.monthArray;
        } else {
          this.timeSection = this.timeArray;
        }
      }
      this.$emit("timeChange", this.timeSection);
    },
    // 重置成指定的值
    resetTime2(defalutDate) {
      this.timeSection = [...defalutDate];
      this.$emit("timeChange", this.timeSection);
    },
    // 点击确定改变时间，告诉父组件
    changeTime(val) {
      if (val) {
        this.$emit("timeChange", val);
      } else {
        this.$emit("timeChange", ["", ""]);
      }
    },
  },
  mounted() {
    this.resetTime();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.el-icon-date-custom{
  position: relative
  left: -26px;
}
</style>
